@import "../../../../assets/scss/variables.scss";
@import "src/assets/scss/typography";

.oh-athlete-report{

  &-athlete-selector {
    /* @author Carden
     * class: `&-athlete-selector` overrides input
     * interaction styles to match pre-existing */
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
      padding: 16px 0 !important;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
      border-bottom-width: 1px;
      border-bottom-color: #83E2C2;
    }
  }

  .mui-text-field {
    /* @author Carden
     * class: `&-athlete-selector` overrides input
     * interaction styles to match pre-existing */
    // .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiInput-input:first-child {
      padding: 16px 0 !important;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
      border-bottom-width: 1px;
      border-bottom-color: #83E2C2;
    }
  }

  .input-wrapper {
    margin-top: 30px;
  }
  .oh-form-error {
    margin-top: 10px;
    height: 14px;
    font-size: $typographyLabelText;
    line-height: 14px;
    color: $globalRed;
  }
  .btn-primary.module-modal {
    padding: 25px;
    text-align: center;
    background: $white;
    box-shadow: 4px 4px 20px $transparentBlack;
    border-radius: 20px;
    font-size: $typographySizeH3;
    line-height: 50px;
    color: $darkBg;
    width: 100%;
    border: none;
    &:active,
    &:focus {
      box-shadow: 4px 4px 20px $transparentBlack!important;
      background-color: $white;
      color: $darkBg;
      border-color: unset;
    }
  }
  .module-card-wrapper{
    position: relative;

    &-clickable {
      cursor: pointer;
    }

    .module-card{
      display: flex;
      margin-bottom: 20px;
      min-height: 100px;
      border-radius: 20px;
      background: $white;
      box-shadow: 4px 4px 20px $transparentBlack;
      padding-top: 8px;
      padding-bottom: 8px;

      .module-image{
        margin: auto 25px;
        width: 50px;
        height: 40px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        &.icon-0{
          background-image: url("../../../../assets/images/athleteKPI.svg");
        }
        &.icon-1{
          background-image: url("../../../../assets/images/line-graph.svg");
        }
        &.icon-2{
          background-image: url("../../../../assets/images/table-view-logo.svg");
        }
        &.icon-3{
          background-image: url("../../../../assets/images/rag-status.svg");
        }
        &.icon-4{
          background-image: url("../../../../assets/images/comparison-graph.svg");
        }
      }

      .module-text {
        flex: 1;
        min-width: 0;
        font-weight: 500;
        font-size: $typographySizeH4;
        line-height: 32px;
        color: $darkBg;
        margin: auto 0 auto 0;

        .module-time {
          font-weight: 300;
        }
      }

      .action-buttons {
        position: absolute;
        right: 20px;
        top: 15px;
      }

      &.is-disabled{
        opacity: 0.5;
      }
    }

    .module-notices {
      position: absolute;
      max-width: 210px;
      line-height: 26px;
      font-size: $typographySizeBody1;
      right: -252px;
      top: 0;

      img{
        width: 32px;
      }
    }
  }
  .add-report{
    margin-top: 40px;
  }


  // Custom Checkbox
  .custom-checkbox{
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    align-items: center;
    color: $darkBg;
    height: 24px;

    input{
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:checked ~ .checkmark{
        background: $darkBg;
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark{
      margin-right: 10px;
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 1px solid $darkBg;
      box-sizing: border-box;

      &:after{
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 2px;
        width: 8px;
        height: 15px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  // End Custom Checkbox

  .plus-icon {
    margin-right: 20px;
    height: 30px;
    vertical-align: text-bottom;
  }

  .oh-report-gen-overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $darkBgTransparent;
    z-index: 0;
    transition: 250ms all ease-in-out;
    opacity: 0;
    visibility: hidden;

    &.is-active{
      opacity: 1;
      visibility: visible;
      z-index: 9;

      .oh-report-gen-popup{
        transform: scale(1);
      }
    }

    .oh-report-gen-popup{
      position: relative;
      margin: 25vh auto;
      padding: 40px;
      border-radius: 20px;
      width: 430px;
      background: $white;
      transform: scale(0.8);
      transition: 200ms transform ease-in-out;
      transition-delay: 100ms;

      .oh-report-gen-close{
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
        margin: 0;
        padding: 0;
        border: 0;
        outline: none;
        background: transparent;
        width: 18px;
        height: 18px;

        img{
          display: block;
          margin: 0;
          width: 100%;
        }
      }
      .oh-rep-gen-progress{
        display: flex;
        min-height: 150px;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        .title{
          font-size: $typographySizeH3;
          line-height: 24px;
          color: $valueGrey;

          &.has-error{
            font-size: $typographySizeBody2;
            line-height: 16px;
            color: $globalRed;
          }
        }

        img.error-icon{
          width: 40px;
        }

        .oh-rep-gen-loader{
          border: 3px solid $lightGrey;
          border-radius: 50%;
          border-top: 3px solid $buttonCyan;
          width: 40px;
          height: 40px;
          -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
        }
      }
    }
  }

  .report-email-list{
    min-height: 60px;

    .oh-value-wrapper{
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 10px 15px 10px 19px;
      background: $linkBlue;
      border-radius: 50px;
      color: $white;

      .oh-value{
        font-size: $typographySizeBody2;
        line-height: 16px;
      }
      button{
        display: inline-block;
        margin-left: 14px;
        border: none;
        padding: 0;
        background: transparent;
        width: 18px;
        height: 16px;
        vertical-align: sub;
        outline: none;

        img{
          display: block;
          max-width: 100%;
          transform: rotate(45deg);
          width: 17px;
          transition: 200ms opacity ease-in-out;
        }

        &:hover{
          img{
            opacity: 0.8;
          }
        }
      }
    }
  }
}

