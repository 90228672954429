.oh-custom-color-picker{
  .oh-color-clickable{
    display: inline-block;
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 16px 0 0;

    &.selected:after {
      display: block;
      border: 1px solid #afafaf;
      position: absolute;
      left: -3px;
      top: -3px;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      content: " ";
    }
  }
}
