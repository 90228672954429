@import "../../assets/scss/variables";
@import "src/assets/scss/typography";

.oh-confirmation-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $darkBgTransparent;
  z-index: 0;
  transition: 250ms all ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
    z-index: 9;

    .oh-confirmation-popup {
      transform: scale(1);
    }
  }

  .oh-confirmation-popup {
    position: relative;
    margin: 25vh auto;
    padding: 70px 60px 50px;
    border-radius: 20px;
    width: 455px;
    background: $white;
    transform: scale(0.8);
    transition: 200ms transform ease-in-out;
    transition-delay: 100ms;

    .oh-confirmation-close {
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      background: transparent;
      width: 18px;
      height: 18px;
    }

    .oh-confirmation-inner {
      .oh-confirmation-title {
        margin-bottom: 40px;
        font-size: $typographySizeH1;
        line-height: 28px;
        color: $borderDark;
        text-align: center;
      }
      button {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
