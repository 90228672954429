@import "../../assets/scss/variables";

.wrapper-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  width: 100%;
  height: 100%;

  &.small {
    position: absolute;
    background: "transparent";
    border-radius: 20px;

    .loader {
      border-width: 3px;
      width: 30px;
      height: 30px;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
    }
  }

  &.has-dark-bg {
    background: $darkBg;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid $buttonCyan;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
