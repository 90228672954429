/*  BUTTON COMPONENT  */
@import "../../assets/scss/variables";
@import "src/assets/scss/typography";

.oh-button {
  display: inline-block;
  border: none;
  padding: 16px 0 15px;
  border-radius: 25px;
  font-size: $typographySizeButton;
  font-weight: 500;
  line-height: 19px;
  color: $white;
  outline: none;
  width: 100%;
  transition: 200ms opacity ease;

  &.btn-dark {
    background: $darkBg;
    &:active {
      background: $darkBgButtonActive;
    }
  }
  &.btn-cyan {
    background: $buttonCyan;

    &:active {
      background: $buttonCyanActive;
    }
  }
  &.btn-red {
    background: $globalRed;

    &:active {
      background: $globalRed;
    }
  }
  &.btn-transparent {
    background: transparent;
    border: 1px solid $white;

    &:active {
      border: 2px solid $borderDark;
      padding: 15px 0 14px;
    }
  }
  &.btn-transparent-dark {
    background: #fff;
    color: $borderDark;
    border: 1px solid $borderDark;

    &:active {
      border: 2px solid $borderDark;
      padding: 15px 0 14px;
    }
  }

  &.btn-transparent-red {
    background: transparent;
    color: $globalRed;
    border: 1px solid $globalRed;

    &:active {
      border: 2px solid $globalRed;
      padding: 15px 0 14px;
    }
  }

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
