@import "../../assets/scss/variables";
@import "src/assets/scss/typography";

.oh-report{
  .oh-section-title-wrapper{
    .oh-report-section-title{
      font-weight: 300;
      font-size: $typographySizeH1;
      line-height: 28px;
      color: $darkBg;
    }
  }

  .oh-report-list-wrapper{
    margin-top: 40px;
    min-height: 50vh;

    .report-template {
      display: flex;
      margin: 0 0 12px;
      padding: 16px 16px;
      align-items: center;
      justify-content: space-between;
      font-size: $typographySizeH4;
      line-height: 19px;
      box-shadow: 4px 4px 20px $transparentBlack;
      background: $white;
      cursor: pointer;

      .report-name{
        display: flex;
        align-items: center;
        color: $valueGrey;

        .type-icon{
          margin-right: 15px;
          width: 24px;
          pointer-events: none;
        }
      }
      .report-data{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: $plholderGrey;

        & > div {
          width: 50%;
          text-align: left;
        }
      }
    }
    .no-templates{
      margin-top: 20vh;
      font-weight: 300;
      font-size: $typographySizeH1;
      line-height: 50px;
      color: $valueGrey;
      text-align: center;
    }
  }
}
