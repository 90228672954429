@import "../../../../../assets/scss/variables";
@import "src/assets/scss/typography";

.oh-report-module-modal-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $darkBgTransparent;
  transition: 250ms all ease-in-out;
  z-index: 9;
  overflow-y: auto;

  .oh-leader-board-modal{
    position: relative;
    margin: 10vh auto 0;
    padding: 25px 40px 60px;
    border-radius: 20px;
    width: 630px;
    background: $white;
    transition: 200ms transform ease-in-out;
    &.module-form{
      padding: 45px 90px 60px;
    }

    .oh-lbm-close{
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      background: transparent;
      width: 18px;
      height: 18px;
      &.back-arrow{
        width: 25px;
        height: 20px;
        right: unset;
        left: 22px;
      }

      img{
        display: block;
        margin: 0;
        width: 100%;
      }
    }

    .oh-lbm-inner{
      .oh-modal-title{
        font-size: $typographySizeH1;
        line-height: 50px;
        text-align: center;    
        margin-bottom: 20px;
        color: $valueGrey;
      }
      .oh-form-error {
        margin-top: 10px;
        height: 14px;
        font-size: $typographyLabelText;
        line-height: 14px;
        color: $globalRed;
      }
      .input-wrapper{
        .label{
          color: $labelGrey;
        }
        .oh-exercise-set-picker-wrapper{
          margin-top: 10px;
          height: 280px;
          .picker-body{
            .options-list{
              height: 185px;

              .single-option{
                .input-wrapper{
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .selected-exercise-sets{
        margin-top: 20px;

        .single-set{
          display: flex;
          margin: 0 10px 10px 0;
          padding: 10px 15px 10px 19px;
          background: $darkBg;
          border-radius: 50px;
          color: $white;
          width: max-content;

          .set-title{
            font-size: $typographySizeBody2;
            line-height: 16px;
          }
          .set-remove{
            display: inline-block;
            margin-left: 14px;
            border: none;
            padding: 0;
            background: transparent;
            width: 18px;
            height: 16px;
            vertical-align: sub;
            outline: none;

            img{
              display: block;
              max-width: 100%;
              transform: rotate(45deg);
              width: 17px;
              transition: 200ms opacity ease-in-out;
              pointer-events: none;
            }

            &:hover{
              img{
                opacity: 0.8;
              }
            }
          }
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
    &.is-editing{
      margin: 5vh auto 0;
    }
  }
  .modules-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .module-area{
    background: $white;
    box-shadow: 3.66555px 3.66555px 18.3278px $transparentBlack;
    border-radius: 20px;
    width: 262px;
    height: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      display: block;
      margin-top: 10px;
    }

    &.is-disabled{
      img, span{
        opacity: 0.3;
      }
    }
  }
}

.report-module-tooltip{
  box-shadow: 4px 4px 20px $transparentBlack;

  .arrow:before{
    border-right-color: #fff;
  }
  .tooltip-inner{
    background-color: #fff;
    color: $darkBg;
    font-weight: 300;
    font-size: $typographySizeBody1;
    line-height: 28px;
  }
}

.add-module, 
.add-metric-button{
  margin-top: 40px;
}
.add-metric-button{
  background: $white!important;
  box-shadow: 4px 4px 20px $transparentBlack;
  border-radius: 20px;
  color: $borderDark;
  font-weight: 500;
  font-size: $typographySizeBody1;
  line-height: 19px;
  border: 1px solid $borderDark;
}
.add-metric-button:active{
  background-color: $white!important;
}
.add-module:active{
  background-color: $downy!important;
  box-shadow: none;
}
