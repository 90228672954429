@import "../../../assets/scss/variables";
@import "src/assets/scss/typography";

.oh-report {
  .create-report {
    margin-top: 80px;
    display: flex;
    align-items: center;
  }
  .oh-report-fixed-cta-wrapper {
    font-weight: 300;
    font-size: $typographySizeH1;
    line-height: 50px;
    color: $valueGrey;
    margin: 0 auto 40px auto;
  }
  .panel-wrapper {
    margin: auto;
    width: 730px;
    .panel {
      width: 350px;
      height: 255px;
      background: $white;
      box-shadow: 4px 4px 20px $transparentBlack;
      border-radius: 20px;
      text-align: center;
      padding-top: 46px;
      margin-left: 15px;
      margin-right: 15px;

      &:first-child {
        padding-top: 60px;
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
      .logo-wrapper {
        margin-bottom: 5px;
      }
      .text-wrapper {
        font-size: $typographySizeH1;
        line-height: 50px;
        color: $darkBg;
      }
    }
  }
}
