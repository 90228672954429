/* FONTS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

/* COLORS */
$black: #000;
$borderDark: #4C4C4C;
$darkBg: #0D1724;
$darkBgTransparent: rgba(13, 23, 36, 0.85);
$darkBgHover: #202A37;
$darkBgActive: #01040A;
$greyBg: #F9F9F9;
$darkBgButtonActive: #212B38;
$linkBlue: #39D0FF;
$globalBlue: #2980B9;
$white: #FFF;
$whiteFourty: rgba(255, 255, 255, 0.4);
$whiteFifty: rgba(255, 255, 255, 0.5);
$whiteSeventyFive: rgba(255, 255, 255, 0.75);
$transparentBlack: rgba(0, 0, 0, 0.12);
$buttonCyan: #83E2C2;
$buttonCyanActive: #6FCEAE;
$globalGreen: #1CE782;
$globalYellow: #F2E148;
$globalOrange: #FFB039;
$globalRed: #C0392B;
$lightGrey: #F6F6F6;
$labelGrey: #828282;
$plholderGrey: #B1B1B1;
$valueGrey: #4D4D4D;
$iconGrey: #656565;
$titleGrey: #9F9F9F;
$inputSwitchersGray: #C4C4C4;
$inputSwitchersBlack: #1B1B1B;
$separatorGrey: #F3F3F3;
$switcherActiveBg: #7AE7A5;
$switcherActiveBullet: #2ADA90;
$switcherInactiveBg: #EB7B9A;
$switcherInactiveBullet: #C0392B;
$alto: #D9D9D9;
$downy: #6FCEAE;

$off-white-background-color: #FAFBFE;
