@import "../../../../assets/scss/variables.scss";
@import "src/assets/scss/typography";

.module-menu {
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  user-select: none;
  margin-top: -4px;
  margin-right: -8px;
}