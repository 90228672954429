@import "../../../../assets/scss/variables";
@import "src/assets/scss/typography";

.oh-athlete-report.group {
  .module-card-wrapper{
    position: relative;

    .module-card{
      display: flex;
      margin-bottom: 20px;
      min-height: 100px;
      border-radius: 20px;
      background: $white;
      box-shadow: 4px 4px 20px $transparentBlack;
      padding-top: 8px;
      padding-bottom: 8px;

      .module-image{
        margin: auto 25px;
        width: 50px;
        height: 40px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        &.icon-5{
          background-image: url("../../../../assets/images/comparison-graph.svg");
        }
        &.icon-6{
          background-image: url("../../../../assets/images/rag-status.svg");
        }
        &.icon-7{
          background-image: url("../../../../assets/images/leaderboard-module-icon.svg");
        }
      }

      .module-text {
        font-weight: 500;
        font-size: $typographySizeH4;
        line-height: 32px;
        color: $darkBg;
        margin: auto 0 auto 20px;

        .module-time {
          font-weight: 300;
        }
      }

      .action-buttons {
        position: absolute;
        right: 20px;
        top: 15px;
      }

      &.is-disabled{
        opacity: 0.5;
      }
    }

    .module-notices {
      position: absolute;
      max-width: 210px;
      line-height: 26px;
      font-size: $typographySizeBody1;
      right: -252px;
      top: 0;

      img{
        width: 32px;
      }
    }
  }
}
