/* OLD Sizes */

// $typographySizeH1: 24px;
// $typographySizeH2: 22px;
// $typographySizeH3: 20px;
// $typographySizeH4: 18px;

// $typographySizeBody1: 16px;
// $typographySizeBody2: 14px;

// $typographySizeButton: 16px;
// $typographySizeInput: 16px;
// $typographyLabelText: 12px;

// $typographyTableHeader: 10px;



/* NEW Sizes */

$typographySizeH1: 22px;
$typographySizeH2: 20px;
$typographySizeH3: 18px;
$typographySizeH4: 16px;

$typographySizeBody1: 14px;
$typographySizeBody2: 12px;

$typographySizeButton: 14px;
$typographySizeInput: 14px;
$typographyLabelText: 10px;

$typographyTableHeader: 10px;
