/*  VARIABLES  */
@import "../../assets/scss/variables";

#root *::-webkit-scrollbar {
  width: 16px;
}

#root *::-webkit-scrollbar-track {
  background-color: transparent;
}

#root *::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #babcbe;
  background-clip: padding-box;
  border: 4px solid transparent;
  min-height: 32px;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}
